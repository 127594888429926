<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-card elevation="5" border="md opacity-4" width="400" class="mx-auto pa-8">
      <v-img class="pa-4 mx-auto" width="200px" :src="require('@/assets/logo.png')" contain></v-img>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent id="forgotPasswordForm">
          <ValidationProvider v-slot="{ errors }" name="Email" rules="required">
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              :error-messages="errors"
              outlined
              name="Email"
              v-on:keyup.enter="submitForm"
              :label="$t('login.emailLabel')"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <v-col cols="12" class="text-right">
            <v-btn
              block
              color="primary"
              :disabled="invalid"
              :loading="loading"
              @click="submitForm"
              class="text-transform"
              >{{ $t("login.resetPasswordLink") }}
              <v-icon small right>mdi-plane</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-right pt-3 pr-6">
            <router-link class="primary--text" :to="{ name: 'login' }"
              >{{ $t("login.backToLogin") }}
            </router-link>
          </v-col>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver
  },
  data() {
    return {
      email: null
    };
  },
  computed: {
    ...mapGetters({
      loading: "options/loading"
    })
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$store.dispatch("users/forgotPassword", this.email).then((res) => {
          if (res.statusCode == 204) {
            this.$store.commit("options/setOptionsData", {
              message: this.$t("login.forgotPasswordSent"),
              snackbar: true,
              color: "success"
            });
            this.email = null;
            this.$refs.observer.reset();
          } else {
            this.$store.commit("options/setOptionsData", {
              message: this.$t("login.forgotPasswordError"),
              snackbar: true,
              color: "error"
            });
          }
        });
      }
    }
  }
};
</script>
<style scoped></style>
